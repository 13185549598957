var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"permissions-page"},[_c('el-page-header',{attrs:{"content":"应用权限"},on:{"back":_vm.goBack}}),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('el-card',{staticClass:"permissions-content"},[_c('div',{staticStyle:{"padding":"15px"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(" 应用权限 ")]),_c('div',{staticStyle:{"border":"1px solid #ccc","height":"calc(100vh - 265px)"}},_vm._l((_vm.applicationList),function(item,index){return _c('div',{key:index,staticClass:"application",style:({
                backgroundColor: item.id == _vm.selectApp.id ? '#f2f2f2' : ''
              })},[_c('div',{staticClass:"clickItem",on:{"click":function($event){return _vm.selectItem(item)}}},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},on:{"change":function($event){return _vm.selectApplication(item, item.selected, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}),_c('font',{style:({
                    color: item.selected == 1 ? '#409EFF' : '',
                    'margin-left': '10px'
                  })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),0)])])],1),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"permissions-content",staticStyle:{"position":"relative"}},[_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_vm._l((['页面权限', '菜单权限', '功能权限']),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"disabled":_vm.rightClick,"label":item,"name":_vm._f("changeNum")(index)}})}),(_vm.activeName == 0)?_c('div',{staticClass:"content",staticStyle:{"height":"calc(100vh - 272px)"}},[(!_vm.rightClick)?_c('el-row',{staticStyle:{"height":"100%"},attrs:{"gutter":20}},[_c('el-col',{staticStyle:{"height":"100%"},attrs:{"span":10}},[_c('div',{staticStyle:{"font-size":"13px","margin":"5px 0px"}},[_vm._v(" 页面 ")]),_c('div',{staticStyle:{"border":"1px solid #ccc","height":"calc(100vh - 300px)"}},[(_vm.pageList.length > 0)?_c('div',{staticStyle:{"height":"100%","overflow-y":"auto"}},_vm._l((_vm.pageList),function(item,index){return _c('div',{key:index,staticClass:"application",style:({
                        backgroundColor:
                          item.id == _vm.selectPageItem.id ? '#f2f2f2' : ''
                      })},[_c('div',{staticClass:"clickItem",on:{"click":function($event){return _vm.pageItem(item)}}},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},on:{"change":function($event){return _vm.selectPage(item)}},nativeOn:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}),_c('font',{style:({
                            color: item.selected == 1 ? '#409EFF' : '',
                            'margin-left': '10px'
                          })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),0):_c('div',{staticClass:"no-data"},[_vm._v(" 暂无数据 ")])])]),_c('el-col',{staticStyle:{"height":"100%"},attrs:{"span":14}},[_c('div',{staticStyle:{"font-size":"13px","margin":"5px 0px"}},[_vm._v(" 元素 ")]),_c('div',{staticStyle:{"border":"1px solid #ccc","height":"calc(100vh - 300px)","padding-top":"10px","padding-bottom":"10px","overflow-y":"auto"}},[_c('el-tree',{ref:"elementTree",staticStyle:{"height":"100%"},attrs:{"data":_vm.elementList,"check-strictly":true,"show-checkbox":"","node-key":"id","default-expand-all":true,"props":_vm.defaultProps},on:{"check-change":_vm.handleElementCheck}})],1)])],1):_vm._e()],1):_vm._e(),(_vm.activeName == 1)?_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"height":"100%","border":"1px solid #ccc","padding":"10px","overflow-y":"auto"}},[_c('el-tree',{ref:"menusTree",staticStyle:{"height":"100%"},attrs:{"data":_vm.menuList,"check-strictly":true,"show-checkbox":"","node-key":"id","default-expand-all":true,"props":_vm.defaultProps},on:{"check":_vm.handleMenusCheck}})],1)]):_vm._e(),(_vm.activeName == 2)?_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"height":"100%","border":"1px solid #ccc","overflow-y":"auto"}},[(_vm.functionList.length > 0)?_c('div',_vm._l((_vm.functionList),function(item,index){return _c('div',{key:index,staticClass:"application",staticStyle:{"padding":"20px"}},[_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},on:{"change":function($event){return _vm.selectAllFunction(item)}},nativeOn:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}),_c('font',{style:({
                      color: item.selected == 1 ? '#409EFF' : '',
                      'margin-left': '10px'
                    })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),0):_c('div',{staticClass:"no-data"},[_vm._v(" 暂无数据 ")])])]):_vm._e()],2),_c('div',{staticClass:"right-version"},[_c('div',{staticClass:"add-name"},[_vm._v(" 资源版本 ")]),_c('el-select',{staticStyle:{"width":"100px"},attrs:{"size":"mini","placeholder":"请选择","disabled":_vm.rightClick},on:{"change":_vm.changVersion},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}},_vm._l((_vm.versionOption),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }